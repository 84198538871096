<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import BuildingGrid from "@/components/app/buildings/BuildingGrid.vue";
import { getBuildingEcmFunctions, getBuildingPartyRoles, getBuildingFilterOptions } from "@/services/api/building.api";
import { getBuildingsFilterToken } from "@/services/api/token.api";
import { FilterTokenName, FilterStatus, mapFilterResponseStatus } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { useUserStore } from "@/stores/user";
import { labels } from "@/composables/miscellaneous.js";

const userStore = useUserStore();
const { token, tokenDelayed, searchToken, mapFilterResult, createFilterToken, resetFilterToken } = handleFilterTokens27(FilterTokenName.Buildings);

const filters = ref([
  {
    name: "Referentie",
    fieldName: "reference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Adres",
    fieldName: "address",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Plaats",
    fieldName: "city",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Functie",
    fieldName: "function",
    type: "list",
    options: [],
  },
  {
    name: "Oppervlakte",
    fieldName: "sqm",
    type: "range",
    options: [{ value: 0 }, { value: 10000 }],
  },
  {
    name: "Datascore",
    fieldName: "dataScore",
    type: "range",
    options: [{ value: 3 }, { value: 98 }],
  },
  {
    name: "Rol",
    fieldName: "partyRole",
    type: "list",
    options: [],
  },
  {
    name: "Energielabel",
    fieldName: "label",
    type: "list",
    options: [...labels],
  },
]);

async function handleFilterSearch(index, text) {
  console.log("Buildingoverview handleFilterSearch: ", index, text, token.value, searchToken.value);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "partyRole":
      {
        const response = await getBuildingPartyRoles(searchToken.value);
        result = response.data;
      }
      break;
    case "function":
      {
        const response = await getBuildingEcmFunctions(searchToken.value);
        result = response.data;
      }
      break;
    case "label":
      result = filters.value[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getBuildingFilterOptions(searchToken.value, filters.value[index].fieldName, text);
        //console.log("Buildingoverview handleFilterSearch response: ", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFiltersChanged(filterData) {
  console.log("Buildingoverview handleFiltersChanged filterData: ", searchToken.value, filterData);
  if (filterData.length > 0) {
    const getFilterToken = getBuildingsFilterToken(searchToken.value, filterData);
    const success = await createFilterToken(getFilterToken);
    if (!success) {
      proxy.$toaster.error("Ophalen van de filter token is mislukt!");
    }
  } else {
    resetFilterToken();
  }
}
</script>

<template>
  <v-container fluid class="app-building-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Overzicht Gebouwen</h1>
        <p>In dit overzicht worden alle gebouwen van uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="6">
        <building-overview-percentage-parisProof :token="tokenDelayed"></building-overview-percentage-parisProof>
      </v-col>
      <v-col md="3" sm="6">
        <building-overview-energy-labels :token="tokenDelayed" labelSource="EPOnline">
          <template v-slot:headerwidget>
            <h3>Verdeling van het label</h3>
          </template>
        </building-overview-energy-labels>
      </v-col>
      <v-col md="3" sm="6">
        <building-overview-square-meters :token="tokenDelayed"></building-overview-square-meters>
      </v-col>
      <v-col md="3" sm="6">
        <building-overview-functions :token="tokenDelayed"></building-overview-functions>
      </v-col>
    </v-row>

    <v-row class="overview-wrapper">
      <v-col>
        <article class="inner">
          <!-- <v-row>
            <v-col>
              <header>
                <h4>Gebouwen filteren</h4>
              </header>
            </v-col>
          </v-row> -->
          <v-row class="overview-filters align-items-start">
            <v-col cols="auto">
              <filter-set :name="FilterTokenName.Buildings" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right" v-if="userStore.hasRoleUser">
              <v-btn class="primary" :to="{ name: 'BuildingsAddCertified' }"> <v-icon dark left>mdi-plus</v-icon> Erkende maatregelen </v-btn>
            </v-col>
          </v-row>
          <building-grid :token="token"></building-grid>
        </article>
      </v-col>
    </v-row>
  </v-container>
</template>
